import store from "@/state/store";

const userString = localStorage.getItem('user');
const userObject = JSON.parse(userString);

export default [
  {
    path: "/d/:hash",
    name: "downloadReport",
    meta: {
      title: "Скачать отчет",
      authRequired: false, // Явно указываем, что авторизация не требуется
    },
    component: () => import("../views/download/report.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Авторизация",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "default" });
          next();
        } else {
          next();
        }
      },
    }
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Приборная панель",
      authRequired: true,
    },
    component: () => import("../views/dashboard/dashboard.vue"),
  },
  {
    path: "/report-data/:hash",
    name: "reportData",
    meta: {
      title: "Информация об отчете",
      authRequired: true,
    },
    component: () => import("../views/report/ReportData.vue"),
  },
  {
    path: "/report-stats/:hash",
    name: "reportStats",
    meta: {
      title: "Сводка отчета",
      authRequired: true,
    },
    component: () => import("../views/report/PrintReportStats.vue"),
  },
  {
    path: "/report/:hash",
    name: "report",
    meta: {
      title: "Создание отчета",
      authRequired: true,
    },
    component: () => import("../views/report/index.vue"),
  },
  {
    path: "/setting/user/:hash",
    name: "settingUser",
    meta: {
      title: "Настройка прав пользователей",
      authRequired: true,
    },
    component: () => import("../views/db/users.vue"),
  },
  {
    path: "/setting/report/:hash",
    name: "settingReport",
    meta: {
      title: "Настройка отчета",
      authRequired: true,
    },
    component: () => import("../views/db/reportName.vue"),
  },
  {
    path: "/setting/colum/:hash",
    name: "settingColum",
    meta: {
      title: "Настройка поиска",
      authRequired: true,
    },
    component: () => import("../views/db/reportColum.vue"),
  },
  {
    path: "/user/setting",
    name: "userSetting",
    meta: {
      title: "Управление пользователями",
      authRequired: true,
    },
    component: () => import("../views/user/setting.vue"),
    beforeEnter: (to, from, next) => {
      if (userObject && userObject.user_role === 'admin') {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: "/ftp/setting",
    name: "ftpSetting",
    meta: {
      title: "Управление FTP",
      authRequired: true,
    },
    component: () => import("../views/ftp/FTPSettings.vue"),
    beforeEnter: (to, from, next) => {
      if (userObject && userObject.user_role === 'admin') {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: "/db/setting",
    name: "dbSetting",
    meta: {
      title: "Управление базами данных",
      authRequired: true,
    },
    component: () => import("../views/db/setting.vue"),
    beforeEnter: (to, from, next) => {
      if (userObject && userObject.user_role === 'admin') {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: "/user/setting/:id",
    name: "userSettingId",
    meta: {
      title: "Настройки пользователя",
      authRequired: true,
    },
    component: () => import("../views/account/setting.vue"),
    beforeEnter: (to, from, next) => {
      if (userObject && userObject.user_role === 'admin') {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: "/email/setting",
    name: "emailSetting",
    meta: {
      title: "Настройки Email",
      authRequired: true,
    },
    component: () => import("../views/email/setting.vue"),
    beforeEnter: (to, from, next) => {
      if (userObject && userObject.user_role === 'admin') {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: "/edit-email/:hash",
    name: "emailSettingHash",
    meta: {
      title: "Настройки Email сообщения",
      authRequired: true,
    },
    component: () => import("../views/email/message.vue"),
    beforeEnter: (to, from, next) => {
      if (userObject && userObject.user_role === 'admin') {
        next();
      } else {
        next('/');
      }
    }
  },

















  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Выход", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout/basic")
  },
  {
    path: "/task-manager/",
    name: "taskManager",
    meta: {
      title: "Task Manger",
      authRequired: true,
    },
    component: () => import("../views/taskManager/task.vue"),
    beforeEnter: (to, from, next) => {
      console.log('to', to)
      console.log('from', from)
      console.log('next', next)
      if (userObject && userObject.user_role === 'admin') {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    meta: {
      title: "Ошибка 404",
      authRequired: true,
    },
    name: 'NotFound',
    component: () => import("../views/errors/404-cover")
  }
];
