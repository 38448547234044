import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {

    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

// Обработка push-уведомлений
self.addEventListener('push', function(event) {
  const payload = event.data ? event.data.text() : 'No payload';
  const options = {
    body: payload,
    icon: './assets/logo.ico',  // Путь к иконке уведомления, если есть
    badge: './assets/logo.ico' // Путь к значку уведомления, если есть
  };

  event.waitUntil(
      self.registration.showNotification('SQL Data Pump', options)
  );
});
