import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from './state/store';
import 'bootstrap/dist/css/bootstrap.css';

import {createBootstrap} from 'bootstrap-vue-next'
import vClickOutside from 'click-outside-vue3';
import VueApexCharts from 'vue3-apexcharts';
import Maska from 'maska';

import VueFeather from 'vue-feather';

import '@/assets/scss/config/material/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import { configureFakeBackend } from './helpers/fake-backend';
import './registerServiceWorker';
configureFakeBackend();

AOS.init({
    easing: 'ease-out-back',
    duration: 1000,
});
const app = createApp(App);

app.config.devtools = true;

app.use(store)
    .use(router)
    .use(VueApexCharts)

    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(i18n)
    .use(vClickOutside)

app.use(createBootstrap({components: true, directives: true}))

app.mount('#app');
